<template>
  <!--begin::Widget 8-->
  <div class="card card-custom card-stretch card-transparent gutter-b" v-bind:class="widgetClass">
    <!--begin::Body-->
    <div class="card-body p-0">
      <div class="d-flex flex-column h-100 justify-content-between">
        <div class="d-flex align-items-start justify-content-between flex-grow-1 p-6">
          <span class="symbol symbol-50 symbol-white mr-2">
            <span class="symbol-label">
              <span class="svg-icon svg-icon-xxl" v-bind:class="widgetIconColor">
                <inline-svg :src="widgetIcon" />
              </span>
            </span>
          </span>
          <div class="d-flex flex-column text-right">
            <span class="font-size-h4 font-weight-bold">{{ title }}</span>
          </div>
        </div>

        <monitoring-time-chart
            :title="title"
            :categories="chartCategories"
            :series="chartSeries"
            :limit="limit"
            :limit-position="limitPosition"
            :limithigh="limithigh"
            :limitlow="limitlow"
            :nlimithigh="nlimithigh"
            :nlimitlow="nlimitlow"
            :background-color="backgroundColor"
            :defaultValue="defaultValue"
            :min="min"
            :max="max"
            :nmin="nmin"
            :nmax="nmax"
            :loading="loading"
            :systemId='systemId'
            :farmElementDcd='farmElementDcd'
            :getLogIssue="getLogIssue"
            :showOutlier="showOutlier"
        ></monitoring-time-chart>
      </div>
    </div>
    <!--end::Body-->
  </div>
  <!--end::Widget 8 -->
</template>

<script>
import MonitoringTimeChart from '@/components/MonitoringTimeChart';

export default {
  name: "MonitoringTimeChartWidget",
  components: {
    MonitoringTimeChart
  },
  props: {
    widgetNumber: String,
    title: String,
    chartCategories: Array,
    chartSeries: Array,
    categories: Array,
    limit: Number,
    limitPosition: String,
    limithigh: Number,
    limitlow: Number,
    nlimithigh: Number,
    nlimitlow: Number,
    defaultValue: Number,
    min: Number,
    max: Number,
    nmin: Number,
    nmax: Number,
    loading: Boolean,
    systemId: Number,
    farmElementDcd: Number,
    getLogIssue: Function,
    showOutlier: Boolean
  },
  data(){
    return {
      widgetColors : {
        '8': '#faf8ff',
        '9': '#f9fbff',
        '10': '#fff9f9',
        '11': '#f8fdff',
        '12': '#f9f9f9',
        '13': '#f9fcff',
        '14': '#fffbf9',
        '15': '#F9FCFF'
      },
      widgetIcons: {
        '8': 'https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Weather/Temperature-empty.svg',
        '9': 'https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Design/Bucket.svg',
        '10': 'https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Design/Mask.svg',
        '11': 'https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Design/Eraser.svg',
        '12': 'https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Weather/Fog.svg',
        '13': 'https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Navigation/Exchange.svg',
        '14': 'https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Weather/Rain5.svg',
        '15': 'https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Weather/Temperature-empty.svg'
      },
      widgetIconColors: {
        '8': 'svg-icon-primary',
        '9': 'svg-icon-info',
        '10': 'svg-icon-danger',
        '11': 'svg-icon-success',
        '12': 'svg-icon-dark',
        '13': 'svg-icon-info',
        '14': 'svg-icon-warning',
        '15': 'svg-icon-info'
      }
    }
  },
  computed: {
    widgetClass(){
      return 'controller-widget' + this.widgetNumber;
    },
    backgroundColor(){
      return this.widgetColors[this.widgetNumber]
    },
    widgetIcon(){
      return this.widgetIcons[this.widgetNumber]
    },
    widgetIconColor(){
      return this.widgetIconColors[this.widgetNumber]
    }

  }
};
</script>
