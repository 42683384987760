<template>
  <div class="page-dashboard">
    <!--begin::Dashboard-->

    <div class="card card-custom gutter-b dashboard-farm-controller">
<!--      <div class="card-header border-0">-->
<!--        <t-farmon-table-header></t-farmon-table-header>-->
<!--      </div>-->

      <div class="card-body p-1">
      <!--begin::controller-tabs-->
        <div class="controller-tabs">
          <b-tabs
              nav-class="pt-5"
          >
            <b-tab v-for="(item, i) in connects" :key="i" :active="i === idx" @click="changeConnect(i)">
              <template slot="title">
                <span class="nav-text font-weight-bolder font-size-h4">{{ item.connectNm }}</span>
              </template>
            </b-tab>

            <div v-if="hideLogIssue" class="card-body pt-0">
              <div class="row">
                <div class="form-inline col-xxl-3 col-lg-3 col-sm-12 mb-3">
                  <label class="text font-size-h6 font-weight-bold mr-5">{{ i18n.startDate }} </label>
                  <b-form-datepicker
                      v-model="startYmd"
                      :locale="locale"
                      class="farm-custom-datepicker"
                      placeholder=""
                      style="width: 200px"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      @input="changeDate"
                  ></b-form-datepicker>
                </div>

                <div class="form-inline col-xxl-3 col-lg-3 col-sm-12 mb-3">
                  <label class="text font-size-h6 font-weight-bold mr-5">{{ i18n.endDate }} </label>
                  <b-form-datepicker
                      v-model="endYmd"
                      :locale="locale"
                      class="farm-custom-datepicker"
                      placeholder=""
                      style="width: 200px"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      @input="changeDate"
                  ></b-form-datepicker>
                </div>

                <div class="form-inline col-xxl-5 col-lg-5 mb-3">
                    <b-list-group horizontal class="mb-0 col-6 text-center" style="margin-right: auto;">
                      <b-list-group-item
                        v-for="(dateRange) in fixedDateRange.dateRanges"
                        button
                        :key="dateRange.key"
                        :active="fixedDateRange.activeDateRange === dateRange.key"
                        @click="onFixedDateRangeChanged(dateRange.key)"
                      >
                        {{ dateRange.date }}
                      </b-list-group-item>
                      <b-list-group-item v-if="!isMobile" button @click="onDownloadExcel" title="엑셀다운로드">
                        <i class="far fa-file-excel"></i>
                      </b-list-group-item>
                    </b-list-group>
                    <div class="form-inline col-6 pr-0 d-flex justify-content-center">
                      <span class="font-weight-normal p-2"> {{i18n.showOutlier}}</span>
                      <b-form-checkbox v-model="showOutlier" name="check-button" class="outlier mt-1" size="lg" switch @change="setStatusInterval"></b-form-checkbox>
                    </div>
                </div>
                  <!-- <b-form-checkbox
                    id="chart-by-date-range"
                    name="chart-by-date-range"
                    size="lg"
                    v-model="isChartByDateRangeCheckBoxSelected"
                    @change='onChartByDateRangeCheckBoxChanged'
                  >
                    {{ i18n.trendLine}}
                  </b-form-checkbox> -->
                <div class="col-xxl-1 col-lg-1 col-sm-12 mt-1 mb-2" >
                  <b-button class="btn btn-dark rounded float-right" size="sm" @click="hideLogIssue = false">
                   {{i18n.logIssue}}<span class="fas fa-reply ml-2"></span>
                  </b-button>
                </div>
              </div>

              <div class="row mb-3">                
                <div class="col-12">
                  <span class="badge badge-light p-4 float-right">{{i18n.logIssueInfo}}</span>
                </div>
                <!-- <div v-if="!isChartByDateRangeCheckBoxSelected" class="d-flex justify-content-end align-items-center flex-wrap">
                  <div class="d-flex flex-wrap">
                    <a href="javascript:;" class="btn btn-icon btn-sm btn-light mr-2 my-1" v-bind:class="{'disabled' : pageNo === 1}" @click="prevPage"><i class="ki ki-bold-arrow-back icon-xs"></i></a>
                    <a href="javascript:;" class="btn btn-icon btn-sm border-0 mr-2 my-1" style="cursor: auto;">{{ pageNo }}</a>
                    <a href="javascript:;" class="btn btn-icon btn-sm btn-light mr-2 my-1" v-bind:class="{'disabled' : !hasMore}" @click="nextPage"><i class="ki ki-bold-arrow-next icon-xs"></i></a>
                  </div>
                </div> -->
              </div>

              <div class="row">
              <!-- <div v-if="!isMobile" class="col-12">
                  <monitoring-total-time-chart-widget
                      v-bind:title="this.i18n.whole"
                      :chart-categories="categories"
                      :temp-chart-series="tempChartSeries"
                      :humidity-chart-series="humidityChartSeries"
                      :co2-chart-series="co2ChartSeries"
                      :ec-chart-series="ecChartSeries"
                      :ph-chart-series="phChartSeries"
                      :loading="loading"
                  ></monitoring-total-time-chart-widget>
                </div> -->

                <div v-if="tempYn" class="col-12">
                  <monitoring-time-chart-widget
                      widget-number="8"
                      v-bind:title="this.i18n.temperature"
                      :chart-categories="categories"
                      :chart-series="[tempChartSeries, ledChartSeries, externalTempChartSeries]"
                      :limit="tempLimit"
                      limitPosition="up"
                      :limithigh="tempLimithigh"
                      :limitlow="tempLimitlow"
                      :nlimithigh="tempnLimithigh"
                      :nlimitlow="tempnLimitlow"
                      :defaultValue="tempDefault"
                      :min="tempMin"
                      :max="tempMax"
                      :nmin="tempnMin"
                      :nmax="tempnMax"
                      :loading="loading"
                      :systemId="systemId"
                      :farmElementDcd="farmElementDcds.temp"
                      :getLogIssue="getLogIssue"
                      :showOutlier="showOutlier"
                  ></monitoring-time-chart-widget>
                </div>

                <div v-if="waterTempYn" class="col-12">
                  <monitoring-time-chart-widget
                      widget-number="15"
                      v-bind:title="this.i18n.waterTemperature"
                      :chart-categories="categories"
                      :chart-series="[waterTempChartSeries, waterTempMeasureChartSeries]"
                      :limit="waterTempLimit"
                      limitPosition="up"
                      :limithigh="waterTempLimithigh"
                      :limitlow="waterTempLimitlow"
                      :defaultValue="waterTempDefault"
                      :min="waterTempMin"
                      :max="waterTempMax"
                      :loading="loading"
                      :systemId="systemId"
                      :farmElementDcd="farmElementDcds.waterTemp"
                      :getLogIssue="getLogIssue"
                      :showOutlier="showOutlier"
                  ></monitoring-time-chart-widget>
                </div>

                <div v-if="humidityYn" class="col-12">
                  <monitoring-time-chart-widget
                      widget-number="9"
                      v-bind:title="this.i18n.humidity"
                      :chart-categories="categories"
                      :chart-series="[humidityChartSeries, airfanChartSeries, externalHumidityChartSeries]"
                      :limit="humidityLimit"
                      limitPosition="up"
                      :limithigh="humidityLimithigh"
                      :limitlow="humidityLimitlow"
                      :nlimithigh="humiditynLimithigh"
                      :nlimitlow="humiditynLimitlow"
                      :defaultValue="humidityDefault"
                      :min="humidityMin"
                      :max="humidityMax"
                      :nmin="humiditynMin"
                      :nmax="humiditynMax"
                      :loading="loading"
                      :systemId="systemId"
                      :farmElementDcd="farmElementDcds.humidity"
                      :getLogIssue="getLogIssue"
                      :showOutlier="showOutlier"
                  ></monitoring-time-chart-widget>
                </div>

                <div v-if="co2Yn && connect.envCtrlType === '제닉스'" class="col-12">
                  <monitoring-time-chart-widget
                      widget-number="10"
                      v-bind:title="this.i18n.CO2"
                      :chart-categories="categories"
                      :chart-series="[co2ChartSeries, fanChartSeries, co2StatusChartSeries]"
                      :limit="co2Limit"
                      limitPosition="down"
                      :limithigh="co2DayLimithigh"
                      :limitlow="co2DayLimitlow"
                      :nlimithigh="co2NightLimithigh"
                      :nlimitlow="co2NightLimitlow"
                      :defaultValue="co2Default"
                      :min="co2Min"
                      :max="co2Max"
                      :loading="loading"
                      :systemId="systemId"
                      :farmElementDcd="farmElementDcds.co2"
                      :getLogIssue="getLogIssue"
                      :showOutlier="showOutlier"
                  ></monitoring-time-chart-widget>
                </div>

                <div v-if="co2Yn && connect.envCtrlType !== '제닉스'" class="col-12">
                  <monitoring-time-chart-widget
                      widget-number="10"
                      v-bind:title="this.i18n.CO2"
                      :chart-categories="categories"
                      :chart-series="[co2ChartSeries, fanChartSeries]"
                      :limit="co2Limit"
                      limitPosition="down"
                      :limithigh="co2DayLimithigh"
                      :limitlow="co2DayLimitlow"
                      :nlimithigh="co2NightLimithigh"
                      :nlimitlow="co2NightLimitlow"
                      :defaultValue="co2Default"
                      :min="co2Min"
                      :max="co2Max"
                      :loading="loading"
                      :systemId="systemId"
                      :farmElementDcd="farmElementDcds.co2"
                      :getLogIssue="getLogIssue"
                      :showOutlier="showOutlier"
                  ></monitoring-time-chart-widget>
                </div>

                <div v-if="ecYn" class="col-12">
                  <monitoring-time-chart-widget
                      widget-number="11"
                      v-bind:title="this.i18n.EC"
                      :chart-categories="categories"
                      :chart-series="[ecChartSeries, pureLevelABChartSeries]"
                      :limit="ecLimit"
                      limitPosition="up"
                      :limithigh="ecLimithigh"
                      :limitlow="ecLimitlow"
                      :defaultValue="ecDefault"
                      :min="ecMin"
                      :max="ecMax"
                      :loading="loading"
                      :systemId="systemId"
                      :farmElementDcd="farmElementDcds.ec"
                      :getLogIssue="getLogIssue"
                      :showOutlier="showOutlier"
                  ></monitoring-time-chart-widget>
                </div>

                <div v-if="phYn && connect.envCtrlType === '제닉스'" class="col-12">
                  <monitoring-time-chart-widget
                      widget-number="12"
                      v-bind:title="this.i18n.pH"
                      :chart-categories="categories"
                      :chart-series="[phChartSeries, pureLevelCChartSeries, pureLevelDChartSeries]"
                      :limit="phLimit"
                      limitPosition="down"
                      :limithigh="phLimithigh"
                      :limitlow="phLimitlow"
                      :defaultValue="phDefault"
                      :min="phMin"
                      :max="phMax"
                      :loading="loading"
                      :systemId="systemId"
                      :farmElementDcd="farmElementDcds.ph"
                      :getLogIssue="getLogIssue"
                      :showOutlier="showOutlier"
                  ></monitoring-time-chart-widget>
                </div>

                <div v-if="phYn && connect.envCtrlType !== '제닉스'" class="col-12">
                  <monitoring-time-chart-widget
                      widget-number="12"
                      v-bind:title="this.i18n.pH"
                      :chart-categories="categories"
                      :chart-series="[phChartSeries, pureLevelCChartSeries]"
                      :limit="phLimit"
                      limitPosition="down"
                      :limithigh="phLimithigh"
                      :limitlow="phLimitlow"
                      :defaultValue="phDefault"
                      :min="phMin"
                      :max="phMax"
                      :loading="loading"
                      :systemId="systemId"
                      :farmElementDcd="farmElementDcds.ph"
                      :getLogIssue="getLogIssue"
                      :showOutlier="showOutlier"
                  ></monitoring-time-chart-widget>
                </div>

                <div class="col-12">
                  <monitoring-time-chart-widget
                      widget-number="13"
                      v-bind:title="i18n.waterLevelSolenoid"
                      :chart-categories="categories"
                      :chart-series="[waterLevelSolenoidSeries]"
                      limitPosition="down"
                      :limitlow="0"
                      :limithigh="1.0"
                      :loading="loading"
                      :systemId="systemId"
                      :farmElementDcd="farmElementDcds.waterLevelSolenoid"
                      :getLogIssue="getLogIssue"
                      :showOutlier="showOutlier"
                  ></monitoring-time-chart-widget>
                </div>

                <div class="col-12">
                  <monitoring-time-chart-widget
                      widget-number="14"
                      v-bind:title="i18n.waterLevelMain"
                      :chart-categories="categories"
                      :chart-series="wlmSeries"
                      limitPosition="down"
                      :limitlow="0"
                      :limithigh="2.0"
                      :loading="loading"
                      :systemId="systemId"
                      :farmElementDcd="farmElementDcds.waterLevelMain"
                      :getLogIssue="getLogIssue"
                      :showOutlier="showOutlier"
                  ></monitoring-time-chart-widget>
                </div>
              </div>
            </div>

            <!--begin::logIssues-->
            <div v-show="hideLogIssue != true" class="row">
              <div class="card-body pt-0 col-xxl-12 col-lg-12 col-sm-12">
                <div class="float-left"><h4 class="ml-2 bg-secondary p-2">{{i18n.logIssue}}</h4></div>
                <b-button class="btn btn-dark rounded float-right" size="sm" @click="hideLogIssue = true">
                  {{i18n.timeSeries}}<span class="fas fa-reply ml-2"></span>
                </b-button>
              </div>
              <div class="card-body pt-0 col-xxl-12 col-lg-12 col-sm-12 mb-3" >
                <v-data-table
                    :headers="fields"
                    :items="items"
                    class="elevation-1 farm-data-table"
                    no-data-text="No Data"
                    hide-default-footer
                >
                </v-data-table>
              </div>
            </div>
          <!--end::logIssues-->

          </b-tabs>
        </div>
        <!--end::controller-tabs-->
      </div>
    </div>
    <!--end::Dashboard-->

    <!-- begin::TableForExcelDownload -->
    <table v-show="false" class="table table-vertical-center dtr-inline text-center table-condensed" id="excel_download_table">
      <thead class="thead-light">
      <tr role="row">
        <th v-for="item in Excelheader" :key="item" class="sorting" rowspan="1" colspan="1">{{item}}</th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="!excelCrops.length">
        <td colspan="14" style="text-align: center">
          <span><br>No Data<br><br></span>
        </td>
      </tr>
      <tr :key="idx" v-for="(item, idx) in excelCrops">
        <td>{{ item.dateTime ? item.dateTime : ''}}</td>
        <td>{{ item.temperature != null ? item.temperature : ''}}</td>
        <td>{{ item.waterTemperature != null ? item.waterTemperature : ''}}</td>
        <td>{{ item.humidity != null ? item.humidity : ''}}</td>
        <td>{{ item.co2 != null ? item.co2 : ''}}</td>
        <td>{{ item.ec != null ? item.ec : ''}}</td>
        <td>{{ item.ph != null ? item.ph : ''}}</td>
        <td>{{ item.led != null ? item.led : ''}}</td>
        <td>{{ item.airfan != null ? item.airfan : ''}}</td>
        <td>{{ item.fan != null ? item.fan : ''}}</td>
        <td>{{ item.purelevelAB != null ? item.purelevelAB : ''}}</td>
        <td>{{ item.purelevelC != null ? item.purelevelC : ''}}</td>
        <td>{{ item.purelevelD != null ? item.purelevelD : ''}}</td>
        <td>{{ item.waterLevelSolenoid != null ? item.waterLevelSolenoid : ''}}</td>
        <td>{{ item.waterLevel != null ? item.waterLevel : ''}}</td>
        <td v-for="(waterLevel, i) in waterLevelMainLen" :key="i">{{ item[`waterLevelMain${i}`] != null ? item[`waterLevelMain${i}`] : ''}}</td>
      </tr>
      </tbody>
    </table>
    <!-- end::TableForExcelDownload -->

  </div>
</template>

<style>
.outlier.custom-switch .custom-control-label::before {
  border-radius: 4px !important;
}
.outlier.custom-switch .custom-control-label::after {
  border-radius: 3px !important;
}
.outlier.custom-switch input:checked ~ .custom-control-label::after {
  left: -34px;
}
</style>

<script>
// import MonitoringTotalTimeChartWidget from '@/components/MonitoringTotalTimeChartWidget';
import MonitoringTimeChartWidget from '@/components/MonitoringTimeChartWidget';
import {
  collectionsCheck,
  getItem,
  getItems,
  getPaging,
  lengthCheck,
  timestampToDate,
  todayToDate,
} from '@/core/services/funcs';
import {ACT_GET_MY_CONNECTS, ACT_GET_CONNECT_COMMENTS, ACT_GET_STATUS_LOGS, ACT_GET_FARM_THRESHOLD, ACT_GET_WEATHER_FARM, ACT_GET_STATUS_MEASURE} from '@/core/services/variable';
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import {ACT_ADD_ALERT, ACT_SHOW_CONFIRM_ALERT} from '@/core/services/store/alert.module';
import {getRefinedHighchartSeriesData, utcToDate, isSuccess} from '@/core/services/funcs.js'
import {mapGetters} from 'vuex';
import i18n from '@/core/plugins/vue-i18n.js';
import {SET_AUTH, PURGE_AUTH, REFRESH_TOKEN} from '@/core/services/store/auth.module';
import * as XLSX from 'xlsx'

export default {
  name: "FarmTimeSeries",
  components: {
    MonitoringTimeChartWidget,
    // MonitoringTotalTimeChartWidget
  },
  beforeMount() {
    const today = todayToDate('yyyy-MM-dd')
    if(Object.keys(i18n._localeChainCache)[0] == 'en'){
      this.locale = 'en'
    }

    // 그래프 시작일을 하루전으로 설정
    let currentDate = new Date();
    const startDate = new Date(currentDate.setDate(currentDate.getDate() - 1 ));
    const startDateUTC = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()));
    this.startYmd = startDateUTC.toISOString().slice(0, 10)
    this.endYmd = today

    if (this.$route.query.startDate != null && this.$route.query.startDate != '')
        this.startYmd = this.$route.query.startDate
    if (this.$route.query.endDate != null && this.$route.query.endDate != '')
        this.endYmd = this.$route.query.endDate

    this.$store.dispatch(ACT_GET_MY_CONNECTS).then(resp => {
      if(lengthCheck(resp)){
        const item = getItem(resp)
        if(collectionsCheck(item.farmConnects, 'seq')) {
          this.fno = item.fno
          this.connects = item.farmConnects.filter(connect => connect.internetConnectYn == 'Y');
          this.connect = this.connects[0]
          this.systemId = this.connect.systemId;
          this.getStatusLogs();
          this.getLogIssue()
          this.getYn(this.connect)
        }
      }
    })
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: i18n.t('topNavBar.farm.info'), icon: 'flaticon2-graph icon-lg' }, { title: i18n.t('topNavBar.farm.timeSeriesInfo') }]);
    window.scrollTo(0,0);
  },
  computed: {
    ...mapGetters(["isMobile","loginStayYn"]),
    Excelheader() {
      const data = ["DateTime", i18n.t('farm.timeseries.temperature'),  i18n.t('farm.timeseries.waterTemperature'), i18n.t('farm.timeseries.humidity')+`(%)`, "CO2", "EC", "pH","LED","AIRFAN","FAN", "pureLevelAB", "pureLevelC", "pureLevelD",i18n.t('farm.timeseries.waterLevelSolenoid'), i18n.t('farm.timeseries.waterLevel')]
      for(let i=1; i<= this.waterLevelMainLen; i++){
        data.push(i18n.t('farm.timeseries.waterLevelMain')+`${i}`)
      }
      return data
    },
  },
  data() {
    return {
      fno: 0,
      idx: 0,
      connects: [],
      connect: {},
      systemId: 0,

      dateCd: 'd',

      thresholdData: [],

      tempDefault: 0,
      humidityDefault: 0,
      co2Default: 0,
      phDefault: 0,
      ecDefault: 0,
      waterTempDefault: 0,

      tempLimit: 0,
      humidityLimit: 0,
      co2Limit: 0,
      phLimit: 0,
      ecLimit: 0,
      waterTempLimit: 0,

      tempLimithigh: 0,
      tempLimitlow: 0,
      tempnLimithigh: 0,
      tempnLimitlow: 0,
      humidityLimithigh: 0,
      humidityLimitlow: 0,
      humiditynLimithigh: 0,
      humiditynLimitlow: 0,
      co2DayLimithigh: 0,
      co2DayLimitlow: 0,
      co2NightLimithigh: 0,
      co2NightLimitlow: 0,
      phLimithigh: 0,
      phLimitlow: 0,
      ecLimithigh: 0,
      ecLimitlow: 0,
      waterTempLimithigh: 0,
      waterTempLimitlow: 0,

      tempMin : 0,
      tempMax : 0,
      humidityMin : 0,
      humidityMax : 0,
      tempnMin : 0,
      tempnMax : 0,
      humiditynMin : 0,
      humiditynMax : 0,
      co2Min : 0,
      co2Max : 0,
      ecMin : 0,
      ecMax : 0,
      phMin : 0,
      phMax : 0,
      waterTempMin : 0,
      waterTempMax : 0,

      categories: [],

      tempChartSeries: [],
      waterTempChartSeries: [],
      waterTempMeasureChartSeries: {data: [], type: 'line', color: 'rgba(255, 50, 50, 0.4)', name: `실측값`, marker: {enabled: true, radius: 3}},
      humidityChartSeries: [],
      co2ChartSeries: [],
      phChartSeries: [],
      ecChartSeries: [],
      waterLevelSolenoidSeries: [],
      waterLevelMainSeries: [],
      waterLevelMainSeriesSub: [],
      wlmSeries: [],
      waterLevelMainLen: 0,
      waterLevelSeries: {data: [], type: 'line', color: 'rgba(0, 0, 180, 0.4)', name: `${i18n.t('farm.timeseries.waterLevel')}`},
      externalTempChartSeries: {data: [], type: 'line', color: 'rgba(255, 50, 50, 0.3)', marker: { enabled: false }, name: `외부온도`, visible: false, yAxis: 1},
      externalHumidityChartSeries: {data: [], type: 'line', color: 'rgba(255, 50, 50, 0.3)', marker: { enabled: false }, name: `외부습도`, visible: false},
      ledChartSeries: {data: [], type: 'areaspline', color: 'rgba(0, 0, 180, 0.4)', name: `${i18n.t('farm.timeseries.temperature')}(led ON)`, opacity: 0.4, },
      airfanChartSeries: {data: [], type: 'area', color: 'rgba(0, 0, 180, 0.4)', name: `${i18n.t('farm.timeseries.humidity')}(airfan ON)`, opacity: 0.4, },
      fanChartSeries: {data: [], type: 'area', color: 'rgba(0, 0, 180, 0.4)', name: 'CO2(fan ON)', opacity: 0.4, },
      pureLevelABChartSeries: {data: [], type: 'scatter', color: 'rgba(204, 0, 204, 1)', name: `${i18n.t('farm.timeseries.EC')}(pureLevelAB ON)`, opacity: 0.5,
        marker: {enabled: true, radius: 4},
        tooltip: {
          headerFormat: '<span style="font-size: 10px">{point.key}</span><br>',
          pointFormat: '<span style="color:{point.color}">●</span> <span style="font-size: 12px">{series.name} : <span class="font-weight-bold">{point.y}</span></span>'
        },
      },
      co2StatusChartSeries: {data: [], type: 'scatter', color: 'rgba(204, 0, 204, 1)', name: `CO2(ON)`, opacity: 0.5,
        marker: {enabled: true, radius: 4},
        tooltip: {
          headerFormat: '<span style="font-size: 10px">{point.key}</span><br>',
          pointFormat: '<span style="color:{point.color}">●</span> <span style="font-size: 12px">{series.name} : <span class="font-weight-bold">{point.y}</span></span>'
        },
      },
      pureLevelCChartSeries: {data: [], type: 'scatter', color: 'rgba(204, 0, 204, 1)', name: `${i18n.t('farm.timeseries.pH')}(pureLevelC ON)`, opacity: 0.5,
      marker: {enabled: true, radius: 4},
        tooltip: {
          headerFormat: '<span style="font-size: 10px">{point.key}</span><br>',
          pointFormat: '<span style="color:{point.color}">●</span> <span style="font-size: 12px">{series.name} : <span class="font-weight-bold">{point.y}</span></span>'
        },
      },
      pureLevelDChartSeries: {data: [], type: 'scatter', color: 'rgba(100, 0, 255, 1)', name: `${i18n.t('farm.timeseries.pH')}(pureLevelD ON)`, opacity: 0.5,
      marker: {enabled: true, radius: 4},
        tooltip: {
          headerFormat: '<span style="font-size: 10px">{point.key}</span><br>',
          pointFormat: '<span style="color:{point.color}">●</span> <span style="font-size: 12px">{series.name} : <span class="font-weight-bold">{point.y}</span></span>'
        },
      },
      statusInterval: null,

      startYmd: '',
      endYmd: '',
      pageNo: 1,
      // hasMore: false,
      loading: false,
      i18n: {
        startDate: i18n.t('farm.timeseries.startDate'),
        endDate: i18n.t('farm.timeseries.endDate'),
        whole: i18n.t('farm.timeseries.whole'),
        temperature: i18n.t('farm.timeseries.temperature'),
        waterTemperature: i18n.t('farm.timeseries.waterTemperature'),
        humidity: i18n.t('farm.timeseries.humidity'),
        CO2: i18n.t('farm.timeseries.CO2'),
        EC: i18n.t('farm.timeseries.EC'),
        pH: i18n.t('farm.timeseries.pH'),
        trendLine: i18n.t('farm.timeseries.trendLine'),
        logIssue: i18n.t('farm.timeseries.logIssue'),
        logIssueInfo: i18n.t('farm.timeseries.logIssueInfo'),
        timeSeries: i18n.t('farm.timeseries.timeSeries'),
        farmNm: i18n.t('farm.timeseries.farmNm'),
        connectNm: i18n.t('farm.timeseries.connectNm'),
        systemId: i18n.t('farm.timeseries.systemId'),
        registrant: i18n.t('farm.timeseries.registrant'),
        registeredDate: i18n.t('farm.timeseries.registeredDate'),
        envType: i18n.t('farm.timeseries.envType'),
        envValue: i18n.t('farm.timeseries.envValue'),
        collectDate: i18n.t('farm.timeseries.collectDate'),
        issue: i18n.t('farm.timeseries.issue'),
        setEndDate: i18n.t('farm.timeseries.setEndDate'),
        threeMonth: i18n.t('farm.timeseries.threeMonth'),
        excelDownloadComplete: i18n.t('farm.timeseries.excelDownloadComplete'),
        noExcelData: i18n.t('farm.timeseries.noExcelData'),
        waterLevelSolenoid: i18n.t('farm.timeseries.waterLevelSolenoid'),
        waterLevelMain: i18n.t('farm.timeseries.waterLevelMain'),
        nutrientPump: i18n.t('farm.timeseries.nutrientPump'),
        showOutlier: i18n.t('farm.timeseries.showOutlier')
      },
      // isChartByDateRangeCheckBoxSelected: true,
      fixedDateRange: {
        activeDateRange: 'day',
        dateRanges: [
          { key: 'day', date: i18n.t('farm.timeseries.day') },
          { key: 'week', date: i18n.t('farm.timeseries.week') },
          { key: 'month', date: i18n.t('farm.timeseries.month') }
        ]
      },
      excelCrops: [],

      hideLogIssue: true,
      fields: [
        { value: 'fieldA', text: i18n.t('farm.timeseries.farmNm')},
        { value: 'fieldB', text: i18n.t('farm.timeseries.connectNm')},
        { value: 'fieldC', text: i18n.t('farm.timeseries.systemId')},
        { value: 'fieldD', text: i18n.t('farm.timeseries.registrant')},
        { value: 'fieldH', text: i18n.t('farm.timeseries.registeredDate')},
        { value: 'fieldF', text: i18n.t('farm.timeseries.envType')},
        { value: 'fieldG', text: i18n.t('farm.timeseries.envValue')},
        { value: 'fieldE', text: i18n.t('farm.timeseries.collectDate')},
        { value: 'fieldI', text: i18n.t('farm.timeseries.issue')}
      ],
      items: [],
      logPageNo: 1,
      logPageSize: 10,
      logTotalCount: 100,

      farmElementDcds : {
        'temp': 1015002,
        'waterTemp': 1015009,
        'humidity': 1015003,
        'co2': 1015007,
        'ec': 1015005,
        'ph': 1015006,
        'led': 1015004,
        'airFlow': 1015008,
        'waterLevel': 1015009,
        'flowSpeed': 1015010,
        'waterLevelSolenoid': 1015013,
        'waterLevelMain': 1015014,
      },
      locale: 'ko',
      showOutlier: false,
      tempYn: true,
      humidityYn: true,
      waterTempYn: true,
      co2Yn: true,
      ecYn: true,
      phYn: true,
    }
  },
  methods: {
    refineHighchartDatetime(datetime) {
      const fixTimezoneHour = 9;

      let refinedDatetime = new Date(datetime)
      refinedDatetime.setHours(refinedDatetime.getHours() + fixTimezoneHour)

      return refinedDatetime
    },
    getTimeFormat(target){
      if(!target) return '-'
      return timestampToDate(target, 'yyyy.MM.dd hh:mm')
    },
    getLogIssue(){
      this.$store.dispatch(`${ACT_GET_CONNECT_COMMENTS}`, {pageNo: this.logPageNo, pageSize: this.logPageSize, systemId: this.systemId})
      .then(resp => {
        if(lengthCheck(resp)) {
          this.items = []
          let items = getItems(resp)
          const paging = getPaging(resp)
          this.logPageSize = paging.pageSize
          this.logTotalCount = paging.totalCount
          this.logPageNo = paging.pageNo
          items.forEach(item =>{
          this.items.push({'fieldA': item.farmNm,'fieldB': item.connectNm,'fieldC': item.systemId,'fieldD': item.memberNm,
            'fieldE': this.getTimeFormat(item.systemDt),'fieldF': item.farmElement,'fieldG': item.elementValue,'fieldH': this.getTimeFormat(item.createDt),'fieldI': item.comment})
          })
        }else{
          this.items = []
        }
      })
    },
    clearStatusLog(){
      this.tempLimit = 0
      this.humidityLimit = 0
      this.co2Limit = 0
      this.phLimit = 0
      this.ecLimit = 0
      this.waterTempLimit = 0

      this.tempLimithigh = 0,
      this.tempLimitlow = 0,
      this.tempnLimithigh = 0,
      this.tempnLimitlow = 0,
      this.humidityLimithigh = 0,
      this.humidityLimitlow = 0,
      this.humiditynLimithigh = 0,
      this.humiditynLimitlow = 0,
      this.co2DayLimithigh = 0,
      this.co2DayLimitlow = 0,
      this.co2NightLimithigh = 0,
      this.co2NightLimitlow = 0,
      this.phLimithigh = 0,
      this.phLimitlow =0,
      this.ecLimithigh = 0,
      this.ecLimitlow =0,
      this.waterTempLimithigh = 0,
      this.waterTempLimitlow =0,

      this.tempMin = 0
      this.tempMax = 0
      this.humidityMin = 0
      this.humidityMax = 0
      this.tempnMin = 0,
      this.tempnMax = 0,
      this.humiditynMin = 0,
      this.humiditynMax = 0
      this.co2Min = 0
      this.co2Max = 0
      this.ecMin = 0
      this.ecMax = 0
      this.phMin = 0
      this.phMax = 0
      this.waterTempMin = 0
      this.waterTempMax = 0

      this.categories = []
      this.tempChartSeries = []
      this.waterTempChartSeries = []
      this.waterTempMeasureChartSeries.data = []
      this.humidityChartSeries = []
      this.co2ChartSeries = []
      this.phChartSeries = []
      this.ecChartSeries = []
      this.waterLevelSolenoidSeries = []
      this.waterLevelMainSeries = []
      this.waterLevelMainSeriesSub = []
      this.waterLevelSeries.data = []
      this.wlmSeries = []
      this.externalTempChartSeries.data = []
      this.externalHumidityChartSeries.data = []
      this.ledChartSeries.data = []
      this.fanChartSeries.data = []
      this.airfanChartSeries.data = []
      this.pureLevelABChartSeries.data = []
      this.pureLevelCChartSeries.data = []
      this.pureLevelDChartSeries.data = []
      this.co2StatusChartSeries.data = []

      this.tempYn = 'Y'
      this.humidityYn = 'Y'
      this.waterTempYn = 'Y'
      this.co2Yn = 'Y'
      this.ecYn = 'Y'
      this.phYn = 'Y'
    },
    setThreshold(){
      let growType = this.connects[this.idx].growType

      this.tempDefault = (this.thresholdData.tempDayMax+this.thresholdData.tempDayMin)/2
      this.humidityDefault = (this.thresholdData.humidityDayMax + this.thresholdData.humidityDayMin)/2
      this.co2Default =(this.thresholdData.co2DayMax + this.thresholdData.co2DayMin)/2
      this.phDefault = (this.thresholdData.phMax + this.thresholdData.phMin)/2
      this.waterTempDefault = (this.thresholdData.waterTempMax + this.thresholdData.waterTempMin)/2

      this.tempLimit = this.thresholdData.tempDayLimithigh
      this.humidityLimit = this.thresholdData.humidityNightLimithigh
      this.co2Limit = this.thresholdData.co2DayLimithigh
      this.phLimit = this.thresholdData.phLimithigh
      this.waterTempLimit = this.thresholdData.waterTempLimithigh

      this.tempLimithigh = this.thresholdData.tempDayLimithigh
      this.tempLimitlow = this.thresholdData.tempDayLimitlow
      this.tempnLimithigh = this.thresholdData.tempNightLimithigh
      this.tempnLimitlow = this.thresholdData.tempNightLimitlow
      this.humidityLimithigh = this.thresholdData.humidityDayLimithigh
      this.humidityLimitlow = this.thresholdData.humidityDayLimitlow
      this.humiditynLimithigh = this.thresholdData.humidityNightLimithigh
      this.humiditynLimitlow = this.thresholdData.humidityNightLimitlow
      this.co2DayLimithigh = this.thresholdData.co2DayLimithigh
      this.co2DayLimitlow = this.thresholdData.co2DayLimitlow
      this.co2NightLimithigh = this.thresholdData.co2NightLimithigh
      this.co2NightLimitlow = this.thresholdData.co2NightLimitlow
      this.phLimithigh = this.thresholdData.phLimithigh
      this.phLimitlow =this.thresholdData.phLimitlow
      this.waterTempLimithigh = this.thresholdData.waterTempLimithigh
      this.waterTempLimitlow =this.thresholdData.waterTempLimitlow

      this.tempMin = this.thresholdData.tempDayLimitlow
      this.tempMax = this.thresholdData.tempDayLimithigh
      this.humidityMin = this.thresholdData.humidityDayLimitlow
      this.humidityMax = this.thresholdData.humidityDayLimithigh
      this.co2Min = this.thresholdData.co2DayLimitlow
      this.co2Max = this.thresholdData.co2DayLimithigh
      this.phMin = this.thresholdData.phLimitlow
      this.phMax = this.thresholdData.phLimithigh
      this.waterTempMin = this.thresholdData.waterTempLimitlow
      this.waterTempMax = this.thresholdData.waterTempLimithigh

      if(growType === '육묘'){
        this.ecDefault = (this.thresholdData.seedEcMax+this.thresholdData.seedEcMin)/2
        this.ecLimit = this.thresholdData.seedEcLimithigh
        this.ecLimithigh = this.thresholdData.seedEcLimithigh
        this.ecLimitlow =this.thresholdData.seedEcLimitlow
        this.ecMin = this.thresholdData.seedEcLimitlow
        this.ecMax = this.thresholdData.seedEcLimithigh
      }else if(growType === '이식'){
        this.ecDefault = (this.thresholdData.transplantEcMax+this.thresholdData.transplantEcMin)/2
        this.ecLimit = this.thresholdData.transplantEcLimithigh
        this.ecLimithigh = this.thresholdData.transplantEcLimithigh
        this.ecLimitlow =this.thresholdData.transplantEcLimitlow
        this.ecMin = this.thresholdData.transplantEcLimitlow
        this.ecMax = this.thresholdData.transplantEcLimithigh
      }else{
        this.ecDefault = (this.thresholdData.ecMin+this.thresholdData.ecMax)/2
        this.ecLimit = this.thresholdData.ecLimithigh
        this.ecLimithigh = this.thresholdData.ecLimithigh
        this.ecLimitlow =this.thresholdData.ecLimitlow
        this.ecMin = this.thresholdData.ecLimitlow
        this.ecMax = this.thresholdData.ecLimithigh
      }
    },
    getStatusLogs(){
      this.clearStatusLog();
      if(this.systemId && this.loading == false) {
        this.loading = true;
        
        Promise.all([
          // this.$store.dispatch(ACT_GET_CONNECT_SET, this.systemId),
          // this.$store.dispatch(ACT_GET_STATUS_LOGS_STATICS, {systemId: this.systemId, dateCd: this.isChartByDateRangeCheckBoxSelected ? 'r':'c', startYmd: this.startYmd.replace(/-/g, ''), endYmd: this.endYmd.replace(/-/g, ''), pageNo: this.pageNo, pageSize: 1000}),
          this.$store.dispatch(ACT_GET_FARM_THRESHOLD, this.fno),
          this.$store.dispatch(ACT_GET_STATUS_LOGS, {systemId: this.systemId, startDate: this.startYmd.replace(/-/g, ''), endDate: this.endYmd.replace(/-/g, ''), field: 'temp_value'}),
          this.$store.dispatch(ACT_GET_STATUS_LOGS, {systemId: this.systemId, startDate: this.startYmd.replace(/-/g, ''), endDate: this.endYmd.replace(/-/g, ''), field: 'water_temp_value'}),
          this.$store.dispatch(ACT_GET_STATUS_LOGS, {systemId: this.systemId, startDate: this.startYmd.replace(/-/g, ''), endDate: this.endYmd.replace(/-/g, ''), field: 'humidity_value'}),
          this.$store.dispatch(ACT_GET_STATUS_LOGS, {systemId: this.systemId, startDate: this.startYmd.replace(/-/g, ''), endDate: this.endYmd.replace(/-/g, ''), field: 'co2_value'}),
          this.$store.dispatch(ACT_GET_STATUS_LOGS, {systemId: this.systemId, startDate: this.startYmd.replace(/-/g, ''), endDate: this.endYmd.replace(/-/g, ''), field: 'co2_status', value: '1', valueType: "String", filterType: "equals"}),
          this.$store.dispatch(ACT_GET_STATUS_LOGS, {systemId: this.systemId, startDate: this.startYmd.replace(/-/g, ''), endDate: this.endYmd.replace(/-/g, ''), field: 'ec_value'}),
          this.$store.dispatch(ACT_GET_STATUS_LOGS, {systemId: this.systemId, startDate: this.startYmd.replace(/-/g, ''), endDate: this.endYmd.replace(/-/g, ''), field: 'ph_value'}),
          this.$store.dispatch(ACT_GET_STATUS_LOGS, {systemId: this.systemId, startDate: this.startYmd.replace(/-/g, ''), endDate: this.endYmd.replace(/-/g, ''), field: 'led'}),
          this.$store.dispatch(ACT_GET_STATUS_LOGS, {systemId: this.systemId, startDate: this.startYmd.replace(/-/g, ''), endDate: this.endYmd.replace(/-/g, ''), field: 'fan'}),
          this.$store.dispatch(ACT_GET_STATUS_LOGS, {systemId: this.systemId, startDate: this.startYmd.replace(/-/g, ''), endDate: this.endYmd.replace(/-/g, ''), field: 'airfan'}),
          this.$store.dispatch(ACT_GET_STATUS_LOGS, {systemId: this.systemId, startDate: this.startYmd.replace(/-/g, ''), endDate: this.endYmd.replace(/-/g, ''), field: 'pure_level_ab', value: '1', valueType: "String", filterType: "equals"}),
          this.$store.dispatch(ACT_GET_STATUS_LOGS, {systemId: this.systemId, startDate: this.startYmd.replace(/-/g, ''), endDate: this.endYmd.replace(/-/g, ''), field: 'pure_level_c', value: '1', valueType: "String", filterType: "equals"}),
          this.$store.dispatch(ACT_GET_STATUS_LOGS, {systemId: this.systemId, startDate: this.startYmd.replace(/-/g, ''), endDate: this.endYmd.replace(/-/g, ''), field: 'pure_level_d', value: '1', valueType: "String", filterType: "equals"}),
          this.$store.dispatch(ACT_GET_STATUS_LOGS, {systemId: this.systemId, startDate: this.startYmd.replace(/-/g, ''), endDate: this.endYmd.replace(/-/g, ''), field: 'water_level_solenoid', value: '1', valueType: "String", filterType: "equals", createEmpty: "true"}),
          this.$store.dispatch(ACT_GET_STATUS_LOGS, {systemId: this.systemId, startDate: this.startYmd.replace(/-/g, ''), endDate: this.endYmd.replace(/-/g, ''), field: 'water_level_main', value: '1', valueType: "String", filterType: "contains"}),
          this.$store.dispatch(ACT_GET_STATUS_LOGS, {systemId: this.systemId, startDate: this.startYmd.replace(/-/g, ''), endDate: this.endYmd.replace(/-/g, ''), field: 'water_level'}),
          this.$store.dispatch(ACT_GET_STATUS_LOGS, {systemId: this.systemId, startDate: this.startYmd.replace(/-/g, ''), endDate: this.endYmd.replace(/-/g, ''), field: 'water_level', value: '1', valueType: "String", filterType: "contains"}),
          this.$store.dispatch(ACT_GET_WEATHER_FARM, {fno: this.fno, startDate: this.startYmd.replace(/-/g, ''), endDate: this.endYmd.replace(/-/g, '')})
        ]).then(([/* settings, logs, */ thresholdResp, tempLogs, waterTempLogs, humidityLogs, co2Logs, co2StatusLogs, ecLogs, phLogs, ledLogs, fanLogs, airfanLogs, pureLevelABLogs, pureLevelCLogs, pureLevelDLogs,waterLevelSolenoidLogs, waterLevelMainLogs, waterLevelLogs, invalidWaterLevel, farmWeather]) => {
          if(!(thresholdResp.items.length == 0)){
            this.thresholdData = thresholdResp.items[0]
          }else{
            this.$store.dispatch(ACT_GET_FARM_THRESHOLD, 0).then(baselineResp =>{
              this.thresholdData = baselineResp.items[0]
              this.setThreshold()
            })
          }
          this.setThreshold()

          this.excelCrops = []

          // if(lengthCheck(logs)) {
            // this.setLineChartDate(getItems(logs));
          const led = getItems(ledLogs)
          const fan = getItems(fanLogs)
          const airfan = getItems(airfanLogs)
          const co2Status = getItems(co2StatusLogs)
          const purelevelab = getItems(pureLevelABLogs)
          const purelevelc = getItems(pureLevelCLogs)
          const pureleveld = getItems(pureLevelDLogs)
          this.getMeasureItems()

          getItems(tempLogs).forEach((log, idx) =>{
            const key = timestampToDate(log.time*1000, 'yyyy/MM/dd hh:mm:00')
            this.excelCrops[idx] = {}
            this.excelCrops[idx].dateTime = key
            this.excelCrops[idx].temperature = log.value/100
            let logTmp = 0
            
            if(this.showOutlier) {
              logTmp = log.value/100
            } else{
              if(this.tempLimithigh*2 < log.value/100) {
                logTmp = 0
              }else{
                logTmp = log.value/100
              }
            }
            this.tempChartSeries.push([key, logTmp])            
            if(led[idx].value != 'N/A' && led[idx].value != 0){
              this.ledChartSeries.data.push([key, logTmp])
              this.excelCrops[idx].led = 1
            }else if(led[idx].value == 0){
              this.excelCrops[idx].led = 0
              this.ledChartSeries.data.push([key, null])
            }else{
              this.excelCrops[idx].led = null
              this.ledChartSeries.data.push([key, null])
            }
          })
          getItems(waterTempLogs).forEach((log, idx) =>{
            const key = timestampToDate(log.time*1000, 'yyyy/MM/dd hh:mm:00')
            this.waterTempChartSeries.push([key, log.value != null ? parseFloat(log.value/100) : 0])
            // this.waterTempChartSeries.push([key, log.value != null && log.value !== 0 ? parseFloat(log.value/100) : null])
            this.excelCrops[idx].waterTemperature = log.value/100
          })
          getItems(humidityLogs).forEach((log, idx) =>{
            const key = timestampToDate(log.time*1000, 'yyyy/MM/dd hh:mm:00')
            let logTmp = 0
            if(this.showOutlier) {
              logTmp = log.value/100
            } else {
              if(this.humidityLimithigh*2 < log.value/100) {
                logTmp = 0
              }else{
                logTmp = log.value/100
              }
            }
            this.humidityChartSeries.push([key, logTmp])
            if(airfan[idx].value != 'N/A' && airfan[idx].value != 0){
              this.airfanChartSeries.data.push([key, logTmp])
              this.excelCrops[idx].airfan = 1
            }else if(airfan[idx].value == 0){
              this.excelCrops[idx].airfan = 0
              this.airfanChartSeries.data.push([key, null])
            }else{
              this.airfanChartSeries.data.push([key, null])
              this.excelCrops[idx].airfan = null
            }
            this.excelCrops[idx].humidity = log.value/100
          })
          getItems(co2Logs).forEach((log, idx) =>{
            const key = timestampToDate(log.time*1000, 'yyyy/MM/dd hh:mm:00')
            let logTmp = 0
            if(this.showOutlier) {
              logTmp = log.value
            } else {
              if(this.co2DayLimithigh*2 < log.value/100) {
                logTmp = 0
              }else{
                logTmp = log.value
              }
            }
            this.co2ChartSeries.push([key, parseInt(logTmp)])
            if(fan[idx].value != 'N/A' && fan[idx].value != 0){
              this.fanChartSeries.data.push([key, parseInt(logTmp)])
              this.excelCrops[idx].fan = 1
            }else if(fan[idx].value == 0){
              this.fanChartSeries.data.push([key, null])
              this.excelCrops[idx].fan = 0
            }else{
              this.fanChartSeries.data.push([key, null])
              this.excelCrops[idx].fan = null
            }
            this.excelCrops[idx].co2 = parseInt(log.value)
          })
          co2Status.forEach((log) => {
            this.co2ChartSeries.some((item,idx) =>{
              const afterTime = (new Date(item[0])).getTime()
              if(afterTime == log.time*1000){
                this.co2StatusChartSeries.data.push(item)
                this.excelCrops[idx].co2Status = 1
                return true
              }else if(afterTime > log.time*1000 && idx != 0){
                const beforeTime = (new Date(this.co2ChartSeries[idx-1][0])).getTime()
                const diff1 = afterTime-beforeTime
                const diff2 = log.time*1000-beforeTime
                const diffValue = Number((item[1]-this.co2ChartSeries[idx-1][1]).toFixed(2))
                this.co2StatusChartSeries.data.push([timestampToDate(log.time*1000, 'yyyy/MM/dd hh:mm:00'),Number((this.co2ChartSeries[idx-1][1] + Number((diff2/diff1*diffValue).toFixed(2))).toFixed(2))])
                this.excelCrops[idx].co2Status = 1
                return true
              }
            })
          })
          getItems(ecLogs).forEach((log, idx) =>{
            const key = timestampToDate(log.time*1000, 'yyyy/MM/dd hh:mm:00')
            let logTmp = 0
            if(this.showOutlier) {
              logTmp = log.value/1000
            } else {
              if(this.ecLimithigh*2 < log.value/1000) {
                logTmp = 0
              }else{
                logTmp = log.value/1000
              }
            }
            this.ecChartSeries.push([key, logTmp])
            this.excelCrops[idx].ec = log.value/1000
          })
          purelevelab.forEach((log) =>{
            this.ecChartSeries.some((item,idx) =>{
              const afterTime = (new Date(item[0])).getTime()
              if(afterTime == log.time*1000){
                this.pureLevelABChartSeries.data.push(item)
                this.excelCrops[idx].purelevelAB = 1
                return true
              }else if(afterTime > log.time*1000 && idx != 0){
                const beforeTime = (new Date(this.ecChartSeries[idx-1][0])).getTime()
                const diff1 = afterTime-beforeTime
                const diff2 = log.time*1000-beforeTime
                const diffValue = Number((item[1]-this.ecChartSeries[idx-1][1]).toFixed(2))
                this.pureLevelABChartSeries.data.push([timestampToDate(log.time*1000, 'yyyy/MM/dd hh:mm:00'),Number((this.ecChartSeries[idx-1][1] + Number((diff2/diff1*diffValue).toFixed(2))).toFixed(2))])
                this.excelCrops[idx].purelevelAB = 1
                return true
              }
            })
          })
          getItems(phLogs).forEach((log, idx) =>{
            const key = timestampToDate(log.time*1000, 'yyyy/MM/dd hh:mm:00')
            let logTmp = 0
            if(this.showOutlier) {
              logTmp = log.value/100
            } else {
              if(this.phLimithigh*2 < log.value/100) {
                logTmp = 0
              }else{
                logTmp = log.value/100
              }
            }
            this.phChartSeries.push([key, logTmp])
            this.excelCrops[idx].ph = log.value/100
          })
          purelevelc.forEach((log) =>{
            this.phChartSeries.some((item,idx) =>{
              const afterTime = (new Date(item[0])).getTime()
              if(afterTime == log.time*1000){
                this.pureLevelCChartSeries.data.push(item)
                this.excelCrops[idx].purelevelC = 1
                return true
              }else if(afterTime > log.time*1000 && idx != 0){
                const beforeTime = (new Date(this.phChartSeries[idx-1][0])).getTime()
                const diff1 = afterTime-beforeTime
                const diff2 = log.time*1000-beforeTime
                const diffValue = Number((item[1]-this.phChartSeries[idx-1][1]).toFixed(2))
                this.pureLevelCChartSeries.data.push([timestampToDate(log.time*1000, 'yyyy/MM/dd hh:mm:00'),Number((this.phChartSeries[idx-1][1] + Number((diff2/diff1*diffValue).toFixed(2))).toFixed(2))])
                this.excelCrops[idx].purelevelC = 1
                return true
              }
            })
          })
          pureleveld.forEach((log) =>{
            this.phChartSeries.some((item,idx) =>{
              const afterTime = (new Date(item[0])).getTime()
              if(afterTime == log.time*1000){
                this.pureLevelDChartSeries.data.push(item)
                this.excelCrops[idx].purelevelD = 1
                return true
              }else if(afterTime > log.time*1000 && idx != 0){
                const beforeTime = (new Date(this.phChartSeries[idx-1][0])).getTime()
                const diff1 = afterTime-beforeTime
                const diff2 = log.time*1000-beforeTime
                const diffValue = Number((item[1]-this.phChartSeries[idx-1][1]).toFixed(2))
                this.pureLevelDChartSeries.data.push([timestampToDate(log.time*1000, 'yyyy/MM/dd hh:mm:00'),Number((this.phChartSeries[idx-1][1] + Number((diff2/diff1*diffValue).toFixed(2))).toFixed(2))])
                this.excelCrops[idx].purelevelD = 1
                return true
              }
            })
          })
          const tmpExcelData = JSON.parse(JSON.stringify(this.excelCrops))
          getItems(waterLevelSolenoidLogs).forEach((log) =>{
            const key = timestampToDate(log.time*1000, 'yyyy/MM/dd hh:mm:00')
            this.waterLevelSolenoidSeries.push([key, log.value != null ? parseInt(log.value) : 0])
            tmpExcelData.some((data, idx) => {
              if(log.time*1000 <= (new Date(data.dateTime)).getTime() && log.value !== null) {
                this.excelCrops[idx].waterLevelSolenoid = log.value != null ? parseInt(log.value) : ''
                return true
              }
            })
          })
          getItems(waterLevelMainLogs).forEach((log,idx) =>{
            const key = timestampToDate(log.time*1000, 'yyyy/MM/dd hh:mm:00')
            const keyFront = timestampToDate(log.time*1000-1, 'yyyy/MM/dd hh:mm:00')
            const keyEnd = timestampToDate(log.time*1000+1, 'yyyy/MM/dd hh:mm:00')
            let wlmlValues = log.value.split(',')
            this.waterLevelMainLen = wlmlValues.length
            if(idx == 0){
              wlmlValues.forEach((wlml,j)=>{
                if(j != 0)this.waterLevelMainSeriesSub.push({ type: 'line', data:[], name: `${this.i18n.nutrientPump}${j+1}`, color: 'rgb(255, 159, 41)'})
              })
            }
            if(wlmlValues[0] != '0' && wlmlValues[0] != 'N/A'){
              this.waterLevelMainSeries.push([keyFront, 0])
              this.waterLevelMainSeries.push([key, parseInt(wlmlValues[0])])
              this.waterLevelMainSeries.push([keyEnd, 0])
              tmpExcelData.some((data, tidx) => {
                if(log.time*1000 <= (new Date(data.dateTime)).getTime()) {
                  this.excelCrops[tidx][`waterLevelMain0`] = parseInt(wlmlValues[0])
                  return true
                }
              })
            }
            if(wlmlValues.length >1){
              wlmlValues.forEach((wlml, i)=>{
                if(i != 0){
                  if(wlml != '0' && wlml != 'N/A'){
                    this.waterLevelMainSeriesSub[i-1].data.push([keyFront, 0])
                    this.waterLevelMainSeriesSub[i-1].data.push([key, parseInt(wlml)])
                    this.waterLevelMainSeriesSub[i-1].data.push([keyEnd, 0])
                    tmpExcelData.some((data, tidx) => {
                      if(log.time*1000 <= (new Date(data.dateTime)).getTime()) {
                        this.excelCrops[tidx][`waterLevelMain${i}`] = parseInt(wlml)
                        return true
                      }
                    })
                  }
                }
              })
            }
          })
          this.wlmSeries.push(this.waterLevelMainSeries)
          if(this.waterLevelMainSeriesSub.length != 0){
            this.waterLevelMainSeriesSub.forEach(sub =>{
              const data = getRefinedHighchartSeriesData(sub.data)
              sub.data = data
              if(sub.data.length == 0 && this.waterLevelMainSeries.length != 0){
                sub.data.push([this.refineHighchartDatetime(this.waterLevelMainSeries[0][0]).getTime(), 0],
                [this.refineHighchartDatetime(this.waterLevelMainSeries[this.waterLevelMainSeries.length-1][0]).getTime(), 0])
              }
              this.wlmSeries.push(sub)
            })
          }
          if(getItems(invalidWaterLevel).length > 0){
            getItems(waterLevelLogs).forEach((log, idx) => {
              const key = timestampToDate(log.time*1000, 'yyyy/MM/dd hh:mm:00')
              if(log.value != 'N/A'){                
                this.waterLevelSeries.data.push([key, Number(log.value)])
                this.excelCrops[idx].waterLevel = Number(log.value)
              }else{
                this.waterLevelSeries.data.push([key, null])
              }
            })
            const wl = getRefinedHighchartSeriesData(this.waterLevelSeries.data)
            this.waterLevelSeries.data = wl
            this.wlmSeries.push(this.waterLevelSeries)
          }
          this.categories.push(new Date(this.startYmd).getTime())

            // this.hasMore = getPaging(logs).hasMore;
          // }else{
            // this.hasMore = false;
          // }

          const sd = getRefinedHighchartSeriesData(this.ledChartSeries.data)
          const fd = getRefinedHighchartSeriesData(this.fanChartSeries.data)
          const afd = getRefinedHighchartSeriesData(this.airfanChartSeries.data)
          const pureAB = getRefinedHighchartSeriesData(this.pureLevelABChartSeries.data)
          const pureC = getRefinedHighchartSeriesData(this.pureLevelCChartSeries.data)
          const pureD = getRefinedHighchartSeriesData(this.pureLevelDChartSeries.data)
          const co2St = getRefinedHighchartSeriesData(this.co2StatusChartSeries.data)
          this.ledChartSeries.data = sd
          this.fanChartSeries.data = fd
          this.airfanChartSeries.data = afd
          this.pureLevelABChartSeries.data = pureAB
          this.pureLevelCChartSeries.data = pureC
          this.pureLevelDChartSeries.data = pureD
          this.co2StatusChartSeries.data = co2St
          this.externalTempChartSeries.data = getItems(farmWeather).map(item => [((item.createDt + 32400000)/1000).toFixed(0)*1000, item.temp], [])
          this.externalHumidityChartSeries.data = getItems(farmWeather).map(item => [((item.createDt + 32400000)/1000).toFixed(0)*1000, item.humidity], [])

          // getItems(farmWeather).forEach((item) => {
          //   if(item.temp != null){
          //     this.tempMax = this.tempMax < item.temp ? item.temp : this.tempMax
          //     this.tempMin = this.tempMin > item.temp ? item.temp : this.tempMin
          //   }
          //   if(item.humidity != null){
          //     this.humidityMax = this.humidityMax < item.humidity ? item.humidity : this.humidityMax
          //     this.humidityMin = this.humidityMin > item.humidity ? item.humidity : this.humidityMin
          //   }
          // })

          this.loading = false;

        }).catch(e => {
          if(this.loginStayYn === 'Y' ) {
            this.refreshToken()
          } else {
            console.error(e)
            this.clearStatusInterval();
            // this.hasMore = false;
  
            this.loading = false;
          }
        });
      }
    },
    getMeasureItems(){
      let params = {startYmd: this.startYmd, endYmd: this.endYmd, systemId: this.systemId}
      this.$store.dispatch(ACT_GET_STATUS_MEASURE, params).then(resp =>{
        let measure = getItems(resp)
        measure.forEach(item => {
          if (item.amPm === 'PM') {
            item.measureYmd += ' 15:00:00'
          } else {
            item.measrueYmd += ' 09:00:00'
          }
          this.waterTempMeasureChartSeries.data.push([new Date(item.measureYmd).getTime() + 32400000, item.waterTempMeasure/100])
        })
      })
    },
    refreshToken() {
      this.$store.dispatch(REFRESH_TOKEN)
      .then(resp => {
        if(isSuccess(resp)){
          this.$store.commit(SET_AUTH, {uuid: resp.uuid, mno: resp.mno, authToken: resp.authToken, refreshToken: resp.refreshToken, loginStayYn: this.loginStayYn});
          this.loading = false;
          this.setStatusInterval();
        } else {
          this.$store.commit(PURGE_AUTH);
          this.$router.push({ name: "signin" });
        }
      }).catch(e => {
        console.error(e);
        this.$store.commit(PURGE_AUTH);
        this.$router.push({ name: "signin" });
      })
    },
    // setLineChartDate(statusLogs){
    //   this.excelCrops = []
    //   statusLogs.forEach(statusLog => {
    //     const key = timestampToDate(statusLog.systemDt, 'yyyy.MM.dd hh:mm')
    //     this.categories.push(statusLog.systemDt)
    //     this.tempChartSeries.push([statusLog.systemDt, statusLog.tempValue/100])
    //     this.humidityChartSeries.push([statusLog.systemDt, statusLog.humidityValue/100])
    //     this.co2ChartSeries.push([statusLog.systemDt, statusLog.co2Value])
    //     this.phChartSeries.push([statusLog.systemDt, statusLog.phValue/100])
    //     this.ecChartSeries.push([statusLog.systemDt, statusLog.ecValue/1000])
    //     this.excelCrops.push({dateTime: key, temperature: statusLog.tempValue/100, humidity: statusLog.humidityValue/100, co2: statusLog.co2Value, ph: statusLog.phValue/100, ec: statusLog.ecValue/1000, led: statusLog.led, fan: statusLog.fan})
    //   })
    // },
    changeConnect(i){
      if(this.startYmd.replace(/-/g, '') > this.endYmd.replace(/-/g, '')) {
          this.$store.dispatch(ACT_ADD_ALERT, {message: this.i18n.setEndDate});
          return;
        }
      this.idx = i;
      this.pageNo = 1;
      if(this.connects[i] && this.connects[i].systemId) {
        this.connect = this.connects[i];
        this.systemId = this.connects[i].systemId;
        this.setStatusInterval();
        this.getLogIssue();
        this.getYn(this.connect)
      }else{
        this.systemId = '';
        this.connect = this.connects[i];
        this.clearStatusInterval();
        this.clearStatusLog();
      }
    },
    setStatusInterval(){
      this.clearStatusInterval();
      this.getStatusLogs();
      this.getYn(this.connect)

      this.statusInterval = setInterval(() => {
        this.getStatusLogs()
        this.getYn(this.connect)
      }, 60000);
    },
    clearStatusInterval(){
      if(this.statusInterval) {
        clearInterval(this.statusInterval);
        this.statusInterval = null;
      }
    },
    changeDate(){
      if(this.startYmd && this.endYmd){
        if(this.startYmd.replace(/-/g, '') > this.endYmd.replace(/-/g, '')) {
          this.$store.dispatch(ACT_ADD_ALERT, {message: this.i18n.setEndDate});
          return;
        }
        this.pageNo = 1;
        this.fixedDateRange.activeDateRange = 'none'
        this.setStatusInterval();
      }
    },
    // prevPage(){
    //   if(this.pageNo > 1){
    //     this.pageNo--;
    //     this.setStatusInterval();
    //   }
    // },
    // nextPage(){
    //   if(this.hasMore){
    //     this.pageNo++;
    //     this.setStatusInterval();
    //   }
    // },
    // onChartByDateRangeCheckBoxChanged() {
    //   this.pageNo = 1;
    //   this.setStatusInterval();
    // },
    onFixedDateRangeChanged(dateRange) {
      if(this.loading ==false){
        if (dateRange === this.fixedDateRange.dateRanges[0].key) {
          this.changeFixedDate(1)
        } else if (dateRange === this.fixedDateRange.dateRanges[1].key) {
          this.changeFixedDate(7)
        } else if (dateRange === this.fixedDateRange.dateRanges[2].key) {
          this.changeFixedMonth(1)
        }

        this.fixedDateRange.activeDateRange = dateRange
        this.isChartByDateRangeCheckBoxSelected = true
        this.setStatusInterval();
      }
    },
    changeFixedDate(day) {
      const today = todayToDate('yyyy-MM-dd')
      let currentDate = new Date(utcToDate(new Date(), 'yyyy/MM/dd hh:mm:00'))
      const startDate = new Date(currentDate.setDate(currentDate.getDate() - day ));
      const startDateUTC = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()));
      this.startYmd = startDateUTC.toISOString().slice(0, 10)
      this.endYmd = today
    },
    changeFixedMonth(month) {
      const today = todayToDate('yyyy-MM-dd')
      let currentDate = new Date(utcToDate(new Date(), 'yyyy/MM/dd hh:mm:00'))
      const startDate = new Date(currentDate.setMonth(currentDate.getMonth() - month ));
      const startDateUTC = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()));
      this.startYmd = startDateUTC.toISOString().slice(0, 10)
      this.endYmd = today
    },
    onDownloadExcel(){
      const yesCallback = () =>{
          if(this.excelCrops.length == 0){
            this.$store.dispatch(ACT_ADD_ALERT, {message: this.i18n.noExcelData, color: 'danger'});
            return
          }

          this.$store.dispatch(ACT_ADD_ALERT, {message: this.i18n.excelDownloadComplete, color: 'success'}).then(() =>{
            const workSheet = XLSX.utils.table_to_sheet(document.getElementById('excel_download_table'), {dateNF:'yyyy/mm/dd hh:mm:ss;@',cellDates:true, raw: true})

            const workBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workBook, workSheet, `sheet1`);
            XLSX.writeFile(workBook, `[${this.startYmd}~${this.endYmd}] ${this.$store.state.farm.farmItem != null ? this.$store.state.farm.farmItem.farmNm : ''} ${this.i18n.timeSeries}.xlsx`);
          })

      }
      this.$store.dispatch(ACT_SHOW_CONFIRM_ALERT, {
        info: { title : i18n.t('farm.timeseries.downloadTimeseriesData')},
        callback: yesCallback
      })
    },
    getDateDiff(date1, date2, kind) {
      let diff = Math.floor((date1.getTime() - date2.getTime()) / (1000 * 60 * 60 * 24));
      if (kind === "m")
        diff = diff / 30;
      else if (kind === "y")
        diff = diff / 30 / 12;

      return diff;
    },
    getYn(connect){
      this.tempYn = connect.tempYn ==='Y' ? true : false;
      this.humidityYn = connect.humidityYn ==='Y' ? true : false;
      this.waterTempYn = connect.waterTempYn === 'Y' ? true : false;
      this.co2Yn = connect.co2Yn ==='Y' ? true : false;
      this.ecYn = connect.ecYn ==='Y' ? true : false;
      this.phYn = connect.phYn ==='Y' ? true : false;
    }
  }
};
</script>