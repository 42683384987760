<template>
  <div style="height: 100%; min-height: 230px">
    <highcharts v-if="series[0] && series[0].length && (series[0].filter(s => s[1]!= null).length != 0)" :options="chartOptions" v-bind:style="{ height: '100%'}"></highcharts>
    <div v-else class="text-center" v-bind:style="{ height: '100%'}">
<!--      <span class="spinner farm-chart-spinner" style="top: 70px;"></span>-->
      <span v-if="loading" class="spinner farm-chart-spinner" style="top: 70px;"></span>
      <span v-else style="top: 70px;"><br><br><br>{{ i18n.noData }}</span>
    </div>

    <b-modal v-model="commentModal"
             title="Comment"
             size="xl"
             centered
             hide-footer
             title-class="font-weight-bold"
    >
      <div class="card-body">
        <!-- begin::major-content -->
        <div class="major-content form-section">
          <!-- begin::form-group -->
          <div class="form-group row">
            <label class="col-sm-2 col-form-label">
              <span class="h5 font-weight-bold">{{i18n.logSystemId}}</span>
            </label>
            <div class="input-group col-sm-4">
              <input v-model="systemId" type="text" class="form-control" disabled>
            </div>
            <label class="col-sm-2 col-form-label">
              <span class="h5 font-weight-bold">{{i18n.logCollectDate}}</span>
            </label>
            <div class="input-group col-sm-4">
              <input v-model="systemDate" type="text" class="form-control" disabled>
            </div>
          </div>
          <!-- end::form-group -->
          <div class="form-group row">
            <label class="col-sm-2 col-form-label ">
              <span class="h5 font-weight-bold">{{i18n.logCollectType}}</span>
            </label>
            <div class="input-group col-sm-4">
              <input v-model="title" type="text" class="form-control" disabled>
            </div>
            <label class="col-sm-2 col-form-label">
              <span class="h5 font-weight-bold">{{i18n.logCollectData}}</span>
            </label>
            <div class="input-group col-sm-4">
              <input v-model="elementValue" type="text" class="form-control" disabled>
            </div>
          </div>

          <!-- begin::form-group -->
          <div class="form-group row">
            <label class="col-sm-2 col-form-label ">
              <span class="h5 font-weight-bold">{{i18n.comment}}</span>
            </label>
            <div class="input-group col-sm-10">
              <div class="input-group">
                <textarea v-model="comment" class="form-control" rows="4"></textarea>
              </div>
            </div>
          </div>
          <!-- end::form-group -->
        </div>
        <!-- end::major-content -->
      </div>
      <!-- end::card-body -->
      <!-- begin::card-footer -->
      <div class="card-footer p-0 pt-5">
        <div class="d-flex align-items-center justify-content-end">
          <div class="button-group">
            <button type="button" class="btn btn-lg btn-secondary mr-2" @click="commentModal = false">{{i18n.cancel}}</button>
            <button type="button" class="btn btn-lg btn-dark" @click="insertComment()">{{i18n.register}}</button>
          </div>
        </div>
      </div>

    </b-modal>
  </div>
</template>

<script>
import {Chart} from 'highcharts-vue'
import i18n from '@/core/plugins/vue-i18n.js'
import {getHighchartYaxisMinMax, getRefinedHighchartSeriesData,timestampToDate, isSuccess} from '@/core/services/funcs.js'
import {ACT_SHOW_CONFIRM_ALERT, ACT_ADD_ALERT, ACT_SHOW_ERROR_ALERT} from '@/core/services/store/alert.module'
import {ACT_INSERT_CONNECT_COMMENT} from '@/core/services/variable';

export default {
  name: 'MonitoringTimeChart',
  components: {
    highcharts: Chart
  },
  props: {
    title: String,
    series: Array,
    limit: Number,
    limitPosition: String,
    limithigh: Number,
    limitlow: Number,
    nlimithigh: Number,
    nlimitlow: Number,
    backgroundColor: String,
    categories: Array,
    defaultValue: Number,
    min: Number,
    max: Number,
    nmin: Number,
    nmax: Number,
    loading: Boolean,
    systemId: Number,
    farmElementDcd: Number,
    getLogIssue: Function,
    showOutlier: Boolean,
  },
  computed: {
    chartOptions(){
      // const nowDate = new Date(this.categories[0] || 0)
      // nowDate.setHours(nowDate.getHours() + 9)
      const refinedSeriesData = getRefinedHighchartSeriesData(this.series[0])

      const startDate = refinedSeriesData[0][0]
      const endDate = refinedSeriesData[refinedSeriesData.length - 1][0]
      const BooleanSeriesCheck = (refinedSeriesData.filter(x => x[1] != null && x[1] != 1 && x[1] != 0).length > 0)
      const zeroVlaueDescription = this.i18n.zeroVlaueDescription
      const showOutlier = this.showOutlier

      const options = {
        chart: {
          zoomType: 'x,y',
          pinchType: 'x,y',
          backgroundColor: this.backgroundColor || '#ffff'
        },
        rangeSelector: {
          selected: 1
        },
        title: {
          text: '<span class="font-weight-bold"></span>'
        },
        plotOptions: {
          series: {
            turboThreshold: 100000
          }
        },
        yAxis: [{
          gridLineWidth: 0,
          plotLines: [{
            dashStyle: 'Dot',
            value: (this.defaultValue || 0),
            width: 1,
            color: 'rgb(50,205,50)',
            label: {
              text:  `${i18n.t('farm.timeseries.targetLine')}( ` + (this.defaultValue || 0) + ' )',
              style: {
                color: 'rgb(50,205,50)',
                fontWeight: 'bold'
              },
              align: 'right'
            },
            // label: {
            //   enabled: false,
            //   x: this.limit
            // },
          }],
          title: {
            text: ''
          }
        }, {
          gridLineWidth: 0,
          opposite: true,
          title: {
            text:''
          }
        }],
        xAxis: {
          type: 'datetime',
          labels: {
            format: '{value:%y.%m.%d %H:%M}'
          },
          min: startDate,
          max: endDate,
        },
        credits: {
          enabled: false
        },
        tooltip: {
          formatter: function () {
            if (this.y === 0) {
              if (showOutlier){
                return `<span class="font-weight-bold" style="font-size: 10px;">${timestampToDate(this.x - (9 * 3600000), 'yyyy.MM.dd hh:mm')}</span> <br> <span style="color: ${this.series.color}">●</span> ${this.series.name}: <span class="font-weight-bold">${this.y}</span>`
              } else {
                return `<span class="font-weight-bold" style="font-size: 10px;">${timestampToDate(this.x - (9 * 3600000), 'yyyy.MM.dd hh:mm')}</span> <br> <span style="color: ${this.series.color}">●</span> ${this.series.name}: <span class="font-weight-bold">${this.y}</span> <br> <span style="font-size: 10px;">${zeroVlaueDescription}</span>`
              }
            } else {
              return `<span class="font-weight-bold" style="font-size: 10px;">${timestampToDate(this.x - (9 * 3600000), 'yyyy.MM.dd hh:mm')}</span> <br> <span style="color: ${this.series.color}">●</span> ${this.series.name}: <span class="font-weight-bold">${this.y}</span>`
            }
          }
        },
        series: [{
          name: this.title,
          threshold: this.limit,
          tooltip: {
            valueDecimals: 2,
          },
          zones: [{
            value: this.limitlow,
            color:'red'
          },{
            value: this.limithigh,
            color:'rgba(100, 149, 237, 0.8)'
          },{
            color:'red'
          }],
          data: refinedSeriesData,
          marker: {
            enabled: false
          },
          //pointStart: nowDate.getTime(),
          //pointInterval: (1000 * 60),

          /* eslint-disable */
          events: {
            click: (event) => {
              let submitDate = new Date(event.point.options.x)
              submitDate.setHours(submitDate.getHours() - 9)
              this.comment = ''
              this.systemDate = timestampToDate(submitDate.getTime(), 'yyyy.MM.dd hh:mm')
              this.elementValue = event.point.options.y
              this.commentModal = true
            }
          }
        }],
      }

      if(this.series.length > 1){
        this.series.forEach((seriesAdd,idx) =>{
          if(idx != 0){
            seriesAdd.events = {
              click: (event) => {
                let submitDate = new Date(event.point.options.x)
                submitDate.setHours(submitDate.getHours() - 9)
                this.comment = ''
                this.systemDate = timestampToDate(submitDate.getTime(), 'yyyy.MM.dd hh:mm')
                this.elementValue = event.point.options.y
                this.commentModal = true
              }
            }
            options.series.push(seriesAdd)
          }
        })
      }

      if(this.min || this.max){
        //options.yAxis.min = (this.min || 0)
        //options.yAxis.max = (this.max || 0)
        const yAxisMinMax = getHighchartYaxisMinMax(this.series[0], this.min, this.max)
        options.yAxis[0].min =  yAxisMinMax.min - yAxisMinMax.min/10
        //yAxis의 최고값 눈금선을 그리게 하기 위해서 실데이터의 최고값의 1/10의 적당한 비율을 더해줌
        options.yAxis[0].max = yAxisMinMax.max + yAxisMinMax.max/10

        options.yAxis[0].startOnTick = false
        options.yAxis[0].endOnTick = false
      }

      // if(this.nmin || this.nmax){
      //   options.yAxis.plotLines.push({
      //     value: (this.nmin || 0),
      //     dashStyle: 'Dot',
      //     width: 2,
      //     color: 'rgb(255,165,0)',
      //     label: {
      //       text: `${i18n.t('farm.timeseries.nminLine')}( ` + (this.nmin || 0) + ' )',
      //       style: {
      //         color: 'rgb(255,165,0)',
      //         fontWeight: 'bold'
      //       },
      //       align: 'left'
      //     },
      //   });

      //   options.yAxis.plotLines.push({
      //     value: (this.nmax || 0),
      //     dashStyle: 'Dot',
      //     width: 2,
      //     color: 'rgba(255,165,0)',
      //     label: {
      //       text: `${i18n.t('farm.timeseries.nmaxLine')}( ` + (this.nmax || 0) + ' )',
      //       style: {
      //         color: 'rgba(255,165,0)',
      //         fontWeight: 'bold'
      //       },
      //       align: 'left'
      //     },
      //   });
      // }

      if(this.limithigh || this.limitlow){
        options.yAxis[0].plotLines.push({
          value: (this.limithigh || 0),
          dashStyle: 'line',
          width: 1,
          color: 'rgba(153,000,153,0.5)',
          label: {
            text: `${i18n.t('farm.timeseries.limithigh')}( ` + (this.limithigh || 0) + ' )',
            style: {
              color: 'rgb(153,000,153)',
              fontWeight: 'bold'
            },
            align: 'right'
          },
        });

        options.yAxis[0].plotLines.push({
          value: (this.limitlow || 0),
          dashStyle: 'line',
          width: 1,
          color: 'rgba(153,000,153,0.5)',
          label: {
            text: `${i18n.t('farm.timeseries.limitlow')}( ` + (this.limitlow || 0) + ' )',
            style: {
              color: 'rgba(153,000,153)',
              fontWeight: 'bold'
            },
            align: 'right'
          },
        });
      }

      if(this.nlimithigh || this.nlimitlow){
        options.yAxis[0].plotLines.push({
          value: (this.nlimithigh || 0),
          dashStyle: 'Dot',
          width: 1,
          color: 'rgba(153,000,153,0.5)',
          label: {
            text: `${i18n.t('farm.timeseries.nlimithigh')}( ` + (this.nlimithigh || 0) + ' )',
            style: {
              color: 'rgb(153,000,153)',
              fontWeight: 'bold'
            },
            align: 'left'
          },
        });

        options.yAxis[0].plotLines.push({
          value: (this.nlimitlow || 0),
          dashStyle: 'Dot',
          width: 1,
          color: 'rgba(153,000,153,0.5)',
          label: {
            text: `${i18n.t('farm.timeseries.nlimitlow')}( ` + (this.nlimitlow || 0) + ' )',
            style: {
              color: 'rgba(153,000,153)',
              fontWeight: 'bold'
            },
            align: 'left'
          },
        });
      }
      return options;
    }
  },
  data (){
    return {
      i18n: {
        noData: i18n.t('farm.timeseries.noData'),
        logSystemId: i18n.t('farm.timeseries.logSystemId'),
        logCollectDate: i18n.t('farm.timeseries.logCollectDate'),
        logCollectType: i18n.t('farm.timeseries.logCollectType'),
        logCollectData: i18n.t('farm.timeseries.logCollectData'),
        comment: i18n.t('farm.timeseries.comment'),
        commentPlz: i18n.t('farm.timeseries.commentPlz'),
        commentSuccess: i18n.t('farm.timeseries.commentSuccess'),
        commentConfirm: i18n.t('farm.timeseries.commentConfirm'),
        cancel: i18n.t('farm.timeseries.cancel'),
        register: i18n.t('farm.timeseries.register'),
        zeroVlaueDescription: i18n.t('farm.timeseries.zeroVlaueDescription')
      },
      comment: '',
      systemDate: '',
      elementValue: '',
      commentModal: false
    }
  },
  methods:{
    insertComment(){
      if(this.comment == ''){
        this.$store.dispatch(ACT_ADD_ALERT, {message: this.i18n.commentPlz});
        return
      }
      const yesCallback = () => {
        this.$store.dispatch(`${ACT_INSERT_CONNECT_COMMENT}`, {
          systemId: this.systemId,
          systemDate: this.systemDate,
          comment: this.comment,
          elementValue: this.elementValue,
          farmElementDcd: this.farmElementDcd
        }).then(resp => {
          if(isSuccess(resp)){
            this.$store.dispatch(ACT_ADD_ALERT, {message: this.i18n.commentSuccess, color: 'success'});
            this.getLogIssue()
            this.commentModal = false
          }else{
            this.$store.dispatch(`${ACT_SHOW_ERROR_ALERT}`, getResult(resp).message)
          }
        }).catch(e => {
          console.error(e)
          this.$store.dispatch(`${ACT_SHOW_ERROR_ALERT}`, null)
        })
      }

      this.$store.dispatch(`${ACT_SHOW_CONFIRM_ALERT}`, {
        info: {title: this.i18n.commentConfirm},
        callback: yesCallback
      })
    }
  }
};
</script>